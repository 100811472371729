<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col lg="5">
        <iq-card class="p-4 top-box">
          <div class="text-center mb-2">
            <h4>{{ $t('reset.reset') }}</h4>
          </div>
          <validationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(changePass)" class="p-3 mb-3">
              <b-row class="justify-content-center">
                <b-col lg="10" class="pt-3">
                  <b-row>
                    <b-col lg="12" class="mb-1">
                      <input-form :type="showPassword?'text':'password'" :placeholder="$t('forms.newPass')"
                                  v-model="info.password"
                                  :validate="'required'" :name="`New password`"/>
                    </b-col>
                    <b-col lg="12" class="mb-4">
                      <input-form :type="showPassword?'text':'password'" :placeholder="$t('forms.confirmationPass')"
                                  v-model="info.password_confirmation"
                                  :validate="'required'" :name="`confirm password`"/>
                    </b-col>
                    <b-col lg="12" class="text-initial">
                      <b-button variant="warning" type="submit" class="w-100">
                        <span class="font-size-16">{{ $t('forms.save') }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </validationObserver>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import authServices from '@/modules/auth/auth.services'
export default {
  data () {
    return {
      info: {
        password: '',
        password_confirmation: '',
        code: this.$route.query.code
      },
      loading: false,
      showPassword: false
    }
  },
  methods: {
    resetForm () {
      this.info = {
        password: '',
        password_confirmation: ''
      }
    },
    changePass () {
      authServices.resetPassword(this.info).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'login' })
      })
    }
  },
  mounted () {
    core.index()
  }
}
</script>
